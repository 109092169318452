import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, RelatedPagesPanel, RelatedPagesGroup, PanelLabel } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from 'react-tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { MdHelpOutline, MdHelp } from "react-icons/md";

import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import GoogleAdWrapper from '../../components/GoogleAd'
import { GumroadAd } from '../../components/GumroadAd'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BaristaFireCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorContainer = styled(ContainerMain)`
  background-color: ${props => props.theme.theme.bg.primary};
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;

  @media (max-width: 500px) {
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const CalculatorGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`



const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const Countdown = styled.div`
  border: 1px solid ${props => props.theme.theme.colors.green};
  background-color: ${props => props.theme.theme.colors.transparentGreen};
  border-radius: 4px;
  padding: 16px;
  font-size: 1rem;
  margin: 24px 0 0 0;

  p{
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 24px 0;
`;

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    // console.log(payload)
    // console.log(label)

    return(
      <TooltipPanel>
        <CardText400>
          <strong>Age: </strong>
          {payload[0].payload.age}
        </CardText400>
        <CardText400>
          <strong>Net Worth (Barista FIRE approach): </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.actualNetWorth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>Net Worth (Staying at day job): </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.continuousGrowthNetWorth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>FIRE number: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.fireNumber.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const BaristaFireCalc = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [ currentAge, setCurrentAge ] = useState(30)
  const [ retirementAge, setRetirementAge ] = useState(67)
  const [ baristaFireAge, setBaristaFireAge ] = useState(45)
  const [ retirementAnnualSpend, setRetirementAnnualSpend ] = useState(40000)
  const [ currentIncome, setCurrentIncome ] = useState(70000)
  const [ currentAnnualSpend, setCurrentAnnualSpend ] = useState(40000)
  const [ investmentRate, setInvestmentRate ] = useState(7)
  const [ inflationRate, setInflationRate ] = useState(3)
  const [ currentNetWorth, setCurrentNetWorth ] = useState(100000)
  const [ SWR, setSWR ] = useState(4)
  // const [ payment, setPayment ] = useState(500)
  const [ baristaFireMonthlyIncome, setBaristaFireMonthlyIncome ] = useState(1500)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(1)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)

  const payment = (currentIncome - currentAnnualSpend) / 12

  const rate = (Math.pow( 1 + (( ( investmentRate - inflationRate ) /100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1


  // const coastNumber = ( annualSpend / (.01*SWR) ) / Math.pow( (1 + (.01*(investmentRate - inflationRate))), (retirementAge - currentAge))
  const fireNumber = retirementAnnualSpend / (.01*SWR)

  let coastFireCountdown = 0
  let fullFireAge = 0
  let fastFireAge = 0

  const calcNetWorthData = () => {
    let netWorthData = []

    for(let i = currentAge; i <= retirementAge; i++){

      const time = i - currentAge
      const localNper = annualPaymentPeriods * time
      const actualNetWorth = currentNetWorth * Math.pow( (1 + (.01*(investmentRate - inflationRate)) ), (i - currentAge)) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )
      // const continuousGrowthNetWorth = currentNetWorth * Math.pow( (1 + (.01*(investmentRate - inflationRate)) ), (i - currentAge)) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )
      // const theoreticalNetWorth = coastNumber * Math.pow( (1 + (.01*(investmentRate - inflationRate)) ), (i - currentAge))

      // if (!coastFireCountdown && actualNetWorth >= theoreticalNetWorth) {
      //   coastFireCountdown = time

      //   if(time == 0){
      //     coastFireCountdown = "You've already hit coast FIRE!"
      //   }
      // }

      // if (i == retirementAge && actualNetWorth < theoreticalNetWorth ){
      //   coastFireCountdown = `You need to increase your monthly contribution to reach Coast FIRE before age ${retirementAge}`
      // }

      const prev = i - currentAge - 1
      // console.log(netWorthData)
      if(baristaFireAge > currentAge){
        if(i <= baristaFireAge){
          if(actualNetWorth <= fireNumber){
            netWorthData.push({
              age: i,
              actualNetWorth: actualNetWorth,
              fireNumber: fireNumber,
              continuousGrowthNetWorth: actualNetWorth
            })
          } else{
            netWorthData.push({
              age: i,
              actualNetWorth: actualNetWorth,
              fireNumber: fireNumber,
              continuousGrowthNetWorth: fireNumber
            })
          }

          if(!fullFireAge){
            if(actualNetWorth > fireNumber){
              fullFireAge = i - 1
            }
          }

        } else {
          // console.log(i)

          const baristaFireAnnualIncome = baristaFireMonthlyIncome * ( (Math.pow(1+rate, annualPaymentPeriods)-1)/ rate )
          // console.log(baristaFireAnnualIncome)

          const localActualNetWorth = netWorthData[prev].actualNetWorth * ( 1 + (.01*(investmentRate - inflationRate)) ) + baristaFireAnnualIncome - retirementAnnualSpend

          if(actualNetWorth <= fireNumber){
            netWorthData.push({
              age: i,
              actualNetWorth: localActualNetWorth ,
              // actualNetWorth: netWorthData[prev].actualNetWorth * ( 1 + (.01*(investmentRate - inflationRate)) ) + baristaFireMonthlyIncome * ( (Math.pow(1+rate, localNper)-1)/ rate ) - retirementAnnualSpend ,
              fireNumber: fireNumber,
              continuousGrowthNetWorth: actualNetWorth
            })

            if(!fullFireAge){
              console.log(localActualNetWorth)
              if(localActualNetWorth > fireNumber){
                fullFireAge = i-1
              }
            }

          } else{
            netWorthData.push({
              age: i,
              actualNetWorth: localActualNetWorth ,
              // actualNetWorth: netWorthData[prev].actualNetWorth * ( 1 + (.01*(investmentRate - inflationRate)) ) + baristaFireMonthlyIncome * ( (Math.pow(1+rate, localNper)-1)/ rate ) - retirementAnnualSpend ,
              fireNumber: fireNumber,
              continuousGrowthNetWorth: fireNumber
            })

            if(!fullFireAge){
              console.log(localActualNetWorth)
              if(localActualNetWorth > fireNumber){
                fullFireAge = i-1
              }
            }

            if(!fastFireAge){
              fastFireAge = i-1
            }
          }

        }
      }
    }
    return netWorthData
  }

  const ResultDialog = () => {
    if(fullFireAge > 0 && fullFireAge <= baristaFireAge){
      return(
        <div>
          <p>Nice! You will reach <strong>full FIRE at age {fullFireAge}</strong>, even before your Barista FIRE age.</p>
          <p></p>
        </div>
      )
    }
    if(!fullFireAge){
      return(
      <div>
        <p>You will not reach full FIRE by age 67. Try increasing your income and decreasing your spending.</p>
      </div>
      )
    } else {
      return(
        <div>
          <p>If you shift to <strong>Barista FIRE at age {baristaFireAge}</strong>, then you will reach <strong>full FIRE at age {fullFireAge}</strong>!</p>
          <p>
              If you stayed at your day job, you would reach <strong>full FIRE at age {fastFireAge}</strong>, which
              is <strong>{fastFireAge - baristaFireAge} {fastFireAge - baristaFireAge > 1 ? 'years' : 'year'}</strong> after your Barista FIRE age.
          </p>
        </div>
      )
    }

  }

  const netWorthData = calcNetWorthData()

  console.log(netWorthData)

  return (
    <Layout>
      <SEO
        title={'Barista FIRE Calculator | WalletBurst'}
        description={'Unlock financial freedom much earlier with side income after quitting your day job'}
        keywords={site.keywords}
      />
      <ReactTooltip />

      <ContainerMain>
        <ContainerContent>
          <h1>Barista FIRE Calculator</h1>
          <H2>Calculate how you can unlock financial freedom much earlier with side income after quitting your day job</H2>
          <p>
            Barista FIRE is where the returns from your invested assets cover most, but not all of your living expenses, and
            you do work that you find enjoyable to make up for the difference and fund the rest of your lifestyle.
          </p>
          <p>
            The big idea behind Barista FIRE is that by generating a relatively small amount of income on the side doing
            work you find enjoyable, you can dramatically reduce the net worth that you need to quit your full-time job
            and exit the rat race. This allows you to gain financial freedom much sooner than if you waited to reach
            your traditional Financial Independence / Retire Early (FIRE) milestone. While the term "Barista FIRE" comes from
            the friendly coffee-making profession, the ways you can make "fun money" are unlimited!.
          </p>
          <p>
            To learn more about the details and math behind Barista FIRE, checkout my
            post: <Link to='/barista-fire'>What is Barista FIRE? The complete guide to unlocking early financial freedom</Link>.
          </p>
        </ContainerContent>
      </ContainerMain>

      <ContainerMain>
        <GoogleAdWrapper /> 
      </ContainerMain>

      <CalculatorContainer>
        <CalculatorGrid>

        <form>
        <InputItem>
          <ThemedTextarea
            id="outlined-name"
            label="Current Age"
            value={currentAge}
            onChange={event => setCurrentAge(event.target.value)}
            variant="outlined"
            size="small"
            fullWidth
          />
          </InputItem>

          <InputItem>
            <ThemedTextarea
              id="outlined-name"
              label="Current Annual Take-Home Pay"
              value={currentIncome}
              onChange={event => setCurrentIncome(event.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />

            <MdHelp
              size="1.2rem"
              data-tip={`This is your current annual take-home pay after all taxes.
                This DOES include any contributions you make to retirement accounts like a 401k or HSA.`}
            />
          </InputItem>

          <InputItem>
            <ThemedTextarea
              id="outlined-name"
              label="Current Annual Expenses"
              value={currentAnnualSpend}
              onChange={event => setCurrentAnnualSpend(event.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />

            <MdHelp
              size="1.2rem"
              data-tip={`This is your current annual expenses. Your annual retirement savings = your annual take home pay - annual expenses`}
            />
          </InputItem>

          <InputItem>
            <ThemedTextarea
              id="outlined-name"
              label="Current Invested Net Worth"
              value={currentNetWorth}
              onChange={event => setCurrentNetWorth(event.target.value)}
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />

            <MdHelp
              size="1.2rem"
              data-tip={`This is the amount of your net worth that you have invested in assets that you expect to
              grow at the Investment Growth Rate which you can adjust below.`}
            />
          </InputItem>

          <InputItem>
            <ThemedTextarea
              id="outlined-name"
              label="Desired Barista FIRE Age"
              value={baristaFireAge}
              onChange={event => setBaristaFireAge(event.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />

            <MdHelp
              size="1.2rem"
              data-tip={`This is the age at which you would quit your day job and begin your "Barista FIRE" phase where you
              earn a side income.`}
            />
          </InputItem>

          <InputItem>
            <ThemedTextarea
              id="outlined-name"
              label="Annual Spending in Retirement"
              value={retirementAnnualSpend}
              onChange={event => setRetirementAnnualSpend(event.target.value)}
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />

            <MdHelp
              size="1.2rem"
              data-tip={`This is your expected annual spending once you begin the Barista FIRE phase.`}
            />
          </InputItem>

          <InputItem>
            <ThemedTextarea
              id="outlined-name"
              label="Monthly Income During Barista FIRE"
              value={baristaFireMonthlyIncome}
              onChange={event => setBaristaFireMonthlyIncome(event.target.value)}
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />

            <MdHelp
              size="1.2rem"
              data-tip={`This is the monthly income you would expect to make during your Barista FIRE phase.`}
            />
          </InputItem>


          <Label>
            Investment growth rate
            <MdHelp size="1.4rem"
                data-tip={`This is your investment's overall (nominal) rate of return before adjusting for inflation.
                Historically, the S&P 500 has returned on average 10% annually from its inception in 1926 to 2018.`}
                />
          </Label>

          <SliderGrid>
            <ThemedSlider
              value={investmentRate}
              aria-labelledby="discrete-slider-always"
              step={0.25}
              min={4}
              max={14}
              marks={[
                {
                  value: 4,
                  label: '4%'
                },
                {
                  value: 7,
                  label: '7%'
                },
                {
                  value: 14,
                  label: '14%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={ (event, value) => setInvestmentRate(value)}
            />

            <ThemedTextarea
              id="outlined-name"
              label="Growth"
              value={investmentRate}
              onChange={event => setInvestmentRate(event.target.value)}
              variant="outlined"
              InputProps={{
                inputComponent: NumberFormatPercentage,
              }}
              fullWidth
            />
          </SliderGrid>

          <Label>
            Inflation rate
            <MdHelp size="1.4rem"
                data-tip={`This is the average annual rate of inflation that you expect to experience in the future.
                Historically, the US economy has experienced an annual average inflation rate of 3%.
                This value is used to normalize the calculator to be in 2020 dollars.`}
            />
          </Label>

          <SliderGrid>

            <ThemedSlider
              value={inflationRate}
              aria-labelledby="discrete-slider-always"
              step={0.25}
              min={2}
              max={6}
              marks={[
                {
                  value: 2,
                  label: '2%'
                },
                {
                  value: 3,
                  label: '3%'
                },
                {
                  value: 6,
                  label: '6%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={ (event, value) => setInflationRate(value)}
            />

            <ThemedTextarea
              id="outlined-name"
              label="Inflation"
              value={inflationRate}
              onChange={event => setInflationRate(event.target.value)}
              variant="outlined"
              InputProps={{
                inputComponent: NumberFormatPercentage,
              }}
              fullWidth
            />
          </SliderGrid>

          <Label>
            Safe Withdrawal Rate
            <MdHelp size="1.4rem"
              data-tip="Safe withdrawal rate (SWR) is the percentage of your net worth that you withdraw each year in retirement.
                 4% is widely considered as the recommended SWR for retirement planning."
                />
          </Label>

          <SliderGrid>
            <ThemedSlider
              value={SWR}
              aria-labelledby="discrete-slider-always"
              step={0.25}
              min={2}
              max={6}
              marks={[
                {
                  value: 2,
                  label: '2%'
                },
                {
                  value: 4,
                  label: '4%'
                },
                {
                  value: 6,
                  label: '6%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={ (event, value) => setSWR(value)}
            />

            <ThemedTextarea
              id="outlined-name"
              label="SWR"
              value={SWR}
              onChange={event => setSWR(event.target.value)}
              variant="outlined"
              InputProps={{
                inputComponent: NumberFormatPercentage,
              }}
              fullWidth
            />
          </SliderGrid>

            {/* <ThemedTextareaPrimary
              id="outlined-read-only-input"
              label="Coast FIRE Number at Current Age"
              value={coastNumber.toFixed(0)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
                readOnly: true
              }}
            /> */}




        </form>

        <GraphDesktop>
          <ResponsiveContainer width='100%' height={700} >
            <AreaChart data={netWorthData}
                margin={{top: 10, right: 0, left: 50, bottom: 30}}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="age" label={{value: "Age (years)", position: "insideBottom", offset: -10}} />
              <YAxis
                tickFormatter={tick => {
                  return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                }}
              />
              {/* <Tooltip/> */}
              <RechartsTooltip
                content={<CustomTooltip/>}
              />
              <Legend verticalAlign="top" height={60} />
              <Area type='monotone' name="Net worth (staying at day job)" dataKey='continuousGrowthNetWorth' stroke='#b4b4b4' strokeDasharray="10 10" fillOpacity={0} />
              {/* <Area type='monotone' name="Coast FIRE number" dataKey='theoreticalNetWorth' stroke='#1274E0' fill='#CCE4FF' /> */}
              <Area type='monotone' name="Net worth (Barista FIRE approach)" dataKey='actualNetWorth' stroke='#3AC732' fill='#CFF0CD' />
              <Area type='monotone' name="Full FIRE number" dataKey='fireNumber' stroke='#FF1733' fillOpacity={0} />
            </AreaChart>
          </ResponsiveContainer>
        </GraphDesktop>

        <GraphMobile>
          <ResponsiveContainer width='100%' height={700} >
            <AreaChart data={netWorthData}
                margin={{top: 10, right: 0, left: 10, bottom: 30}}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="age" label={{value: "Age (years)", position: "insideBottom", offset: -10}} />
              <YAxis
                tickFormatter={tick => {
                  return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                }}
                mirror
              />
              {/* <Tooltip/> */}
              <RechartsTooltip
                content={<CustomTooltip/>}
              />
              <Legend verticalAlign="top" height={170} />
              <Area type='monotone' name="Net worth (staying at day job)" dataKey='continuousGrowthNetWorth' stroke='#b4b4b4' strokeDasharray="10 10" fillOpacity={0} />
              {/* <Area type='monotone' name="Coast FIRE number" dataKey='theoreticalNetWorth' stroke='#1274E0' fill='#CCE4FF' /> */}
              <Area type='monotone' name="Net worth (Barista FIRE approach)" dataKey='actualNetWorth' stroke='#3AC732' fill='#CFF0CD' />
              <Area type='monotone' name="Full FIRE number" dataKey='fireNumber' stroke='#FF1733' fillOpacity={0} />
            </AreaChart>
          </ResponsiveContainer>
        </GraphMobile>
      </CalculatorGrid>

        <Countdown>
          {ResultDialog()}
        </Countdown>

      </CalculatorContainer>

      <GumroadAd />

      <RelatedPagesPanel style={{marginTop: '24px'}}>
        <PanelLabel>Related Pages</PanelLabel>
        <RelatedPagesGroup>
          <Link to='/barista-fire'> Barista FIRE Complete Guide</Link>
          <Link to='/tools/coast-fire-calc'> Coast FIRE Calculator</Link>
          <Link to='/coast-fire'>Coast FIRE Complete Guide</Link>
        </RelatedPagesGroup>
      </RelatedPagesPanel>

      <ContainerTwoCol>


      <ContainerContent>

        <h2>Using this calculator</h2>
        <p>
          I made this calculator to help you plan and visualize what your financial trajectory could look like if you took
          the Barista FIRE approach.
        </p>

        <ul>
          <li>On the left, start by entering your <strong>Current Age</strong>.</li>
          <li>
            <strong>Current Annual Take-Home Pay </strong> is your current yearly income after taxes. This does include
            any contributions you are making to a retirement account like an IRA, 401k, or HSA. If you recieve an employer 401k match,
            you can include this value in your Current Annual Take-Home Pay.
          </li>
          <li>
            <strong>Current Annual Expenses</strong> is your current total yearly cost of living. The calculator uses your <strong>Current Annual Take-Home Pay</strong> less <strong>Current Annual Expenses</strong>
            to calculate your annual contribution to your retirement savings.
          </li>
          <li>
            <strong>Current Invested Net Worth</strong> is the current amount of your net worth that is invested in
            assets that you expect to grow at the <strong>Investment growth rate</strong> which you can adjust below. For example,
            if you have $100,000 invested in the stock market (perhaps through your employer's 401k) and $25,000 saved in your
            emergency fund, then you should enter $100,000 in this field.
          </li>
          <li>
            <strong>Desired Barista FIRE Age</strong> is the age when you would quit your day job and transition to Barista FIRE.
          </li>
          <li>
            <strong>Annual Spending in Retirement</strong> is the amount that you would plan to spend per year after quitting your day job.
            This would be your spending both during the Barista FIRE phase and once you reach full FIRE.
          </li>
          <li>
            <strong>Monthly Income During Barista FIRE</strong> is the average monthly income you would plan to earn from your more enjoyable
            "Barista FIRE" job after quitting your day job.
          </li>
          <li>
            Use the sliders to adjust the rates and watch the graph to the right immediately react to your change!
          </li>
          <li>
            <strong>Investment growth rate </strong> is the average return that you expect your investments to grow, not adjusted for inflation.
            This calculator uses 7% as a default Investment rate of return, which is a relatively conservative assumption.
            Historically, the S&P 500 has returned on average 10% annually from its inception in 1926 to 2018.
          </li>
          <li>
            <strong>Inflation rate </strong> is the average annual rate of inflation that you expect to experience in the future.
            Historically, the US economy has experienced an annual average inflation rate of 3%.
          </li>
          <li>
            <strong>Safe Withdrawal Rate </strong> (SWR) is the estimated percentage of your net worth that you expect to withdraw to cover your living expenses in retirement.
            4% is widely considered as the recommended SWR for retirement planning.
            This 4% withdrawal rate was found by <a href="https://www.bogleheads.org/wiki/Trinity_study_update" target="_blank"> the Trinity Study</a> to have a 100% success rate over a 30-year retirement horizon with a 50% / 50% mix of stocks and bonds.
          </li>
        </ul>

        <p>
          The green line on the graph represents the growth of your net worth over time with your customized inputs.
          The red line visualizes your full FIRE number. You reach your full FIRE point where the green line crosses the red line.
        </p>

        <p>
          At your Barista FIRE Age, you can see a "bump" in the graph where the slope of the two curves changes.
          This point represents where you would quit your day job. Since you are likely bringing in less income during your
          Barista FIRE phase than previously, the growth of your net worth slows down since it is mostly driven by
          investment returns and not contributions.
        </p>

        <h2>The math behind the calculator</h2>

        <p>
          The code calculates your full FIRE number as follows:
        </p>
        <p>
          <em>(FIRE number) = (annual spending) / SWR</em>
        </p>
        <p>
          This calculator uses the compound interest formula:
        </p>

        <p>
          <em>A = P * ( 1 + n)^t</em>
        </p>

        <p>
          Here A is the final amount, P is the principle (initial amount), n is the annual growth rate, and t is the time in years.
        </p>

        <h2>But what about inflation?</h2>
        <p>
          Inflation is an important variable to account for when planning for retirement decades in the future.
          It is almost certain that we will experience inflation in the future, and for this reason having your money <u>invested in assets</u> and <u>not</u> all stuffed under your mattress is crucially important.
          Assets like stocks and real estate tend to rise with inflation while cash loses value, meaning the best way to preserve your wealth in times of high inflation is to be invested in these "hard" assets.
        </p>

        <p>
          <strong>Don't worry about inflation, it's built-in to the calculator! </strong>
          This calculator accounts for inflation by subtracting the inflation rate (from the input slider) from the investment growth rate of return. This gives an <em>inflation-adjusted </em>
          rate of return which is then used to calculate your Coast FIRE number and draw the graph. With this approach, all the numbers in the calculator are adjusted to be in 2020 dollars.
          Think about it like this - you don't have to worry about cost-of-living increases because it's already skimmed off of your expected investment returns.
        </p>
      </ContainerContent>

      <Sidebar />

      </ContainerTwoCol>

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'barista-fire-calc'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default BaristaFireCalc
